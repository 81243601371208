<template>
  <div class="farm-page">
    <x-table
      :no-data-text="
        CA('dealFacilities_check') ? '暂无数据' : '暂无数据查询权限'
      "
      :loading="loading"
      :columns="columns"
      :data="data"
      :config="config"
      @search="search"
      @page-change="pageChange"
      @add="add"
    ></x-table>
    <Modal
      v-model="modal.show"
      :title="modal.title"
      @on-visible-change="modalShow"
      :width="480"
    >
      <Form ref="form" :model="form" :label-width="120">
        <FormItem label="养殖场：" prop="name">
          <Input v-model="form.name"></Input>
        </FormItem>
        <FormItem label="设施图纸：" prop="drawings">
          <upload-image
            v-model="form.drawings"
            :max="1"
            :clearFlag="!modal.show"
            @input="onSuccess"
          ></upload-image>
          <!-- <Upload action="http://localhost:8080/api/v1/drawing/add">
            <Button icon="ios-cloud-upload-outline">上传图纸</Button>
        </Upload> -->
        </FormItem>
        <FormItem label="设施图片：" prop="img">
          <upload-image
            v-model="form.imgUrl"
            :max="1"
            :clearFlag="!modal.show"
            @input="Success"
          ></upload-image>
        </FormItem>
        <FormItem label="备注：" prop="remark">
          <Input type="textarea" v-model="form.remark"></Input>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
    <picture-view v-model="picture.show" :src="picture.src"></picture-view>
  </div>
</template>

<script>
import pictureView from "@/components/picture_view";
export default {
  components:{
    pictureView
  },
  data() {
    return {
      loading: false,
      picture: {
        show: false,
        src: "",
      },
      columns: [
        {
          title: "养殖场",
          key: "name",
        },
        {
          title: "设施图片",
          render: (h, { row }) => {
            if (row.imgUrl) {
              return (
                <div style="display:flex">
                  {row.imgUrl.split(",").map((item) => {
                    return (
                      <img
                        src={this.getImgUrl(row.servicePath, item)}
                        style="width: 35px;height: 35px;margin-right:10px;cursor:pointer"
                        onClick={() => this.lookPicture(row.servicePath + item)}
                      />
                    );
                  })}
                  <a
                    style="text-align: center;line-height: 35px;padding-left: 20px;"
                    onClick={() => {
                      window.open(row.servicePath + row.imgUrl);
                    }}
                  >
                    下载
                  </a>
                </div>
              );
            } else {
              return <span>--</span>;
            }
          },
        },
        {
          title: "图纸",
          render: (h, { row }) => {
            if (row.drawingUrl) {
              return (
                <div style="display:flex">
                  {row.drawingUrl.split(",").map((item) => {
                    return (
                      <img
                        src={this.getImgUrl(row.servicePath, item)}
                        style="width: 35px;height: 35px;cursor:pointer;margin-right:10px"
                        onClick={() => this.lookPicture(row.servicePath + item)}
                      />
                    );
                  })}
                  <a
                    style="text-align: center;line-height: 35px;padding-left: 20px;"
                    onClick={() => {
                      window.open(row.servicePath + row.drawingUrl);
                    }}
                  >
                    下载
                  </a>
                </div>
              );
            } else {
              return <span>--</span>;
            }
          },
        },
        {
          title: "备注",
          key: "remark",
        },
        {
          title: "操作",
          render: (h, { row }) => {
            return (
              <div>
                {
                  //     this.CA("dealFacilities_edit") && (
                  //   <a
                  //     style="margin-right: 10px"
                  //     onClick={() => this.upload(row)}
                  //   >
                  //     上传
                  //   </a>
                  // )
                }
                {this.CA("farmBlueprint_del") && (
                  <Poptip
                    confirm
                    transfer
                    title="确定删除吗?"
                    on-on-ok={() => this.delete(row.id)}
                  >
                    <a>删除</a>
                  </Poptip>
                )}
              </div>
            );
          },
        },
      ],
      data: [],
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      search_data: {},
      form: {
        farmId: "",
        name: "",
        imgUrl: "",
        companyNo: "",
        remark: "",
        id: "",
      },
      drawingOpenUrl: "",
      imgOpenUrl: "",
    };
  },
  methods: {
    lookPicture(url) {
      this.picture = {
        show: true,
        src: url,
      };
    },
    add(row) {
      this.modal = {
        show: true,
        title: "新增设施",
        submitLoading: false,
      };
    },
    downloder(row) {},
    modalShow() {
        this.$refs.form.resetFields();
    },
    submit(row) {
      this.$refs.form.validate().then((res) => {
        let parms = { ...this.form };
        parms.companyNo = localStorage.getItem("companyNo");
        if(this.form.imgUrl!=''){
          parms.imgUrl = "image" + this.form.imgUrl.split("image")[1];
        }
        if(this.form.drawings){
          parms.drawings = "image" + this.form.drawings.split("image")[1];
        }
        this.$post(this.$api.FARM_BLUEPRINT.ADD, {
          farmId: parms.farmId,
          name: parms.name,
          imgUrl: parms.imgUrl,
          drawingUrl: parms.drawings,
          companyNo: parms.companyNo,
          remark: parms.remark,
        }).then((res) => {
          this.modal.show = false;
          this.getList();
        });
      });
    },
    search(data) {
      // if(data.name!=''){
      //     this.data.forEach((item) => {
      //   if (data.name == item.name) {
      //     this.$post(this.$api.FARM_BLUEPRINT.SERCH, {
      //       id: item.id,
      //     }).then((res) => {
      //       this.data = []
      //       this.data.push(res)
      //     });
      //   }
      // });
      // }
      // this.getList()

      this.page.pageNo = 1;
      this.search_data = data;
      this.getList();
    },

    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      //   this.getList();
    },
    getList() {
      this.loading = true
      this.$post(this.$api.FARM_BLUEPRINT.LIST,{
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      }).then((res) => {
        
        this.data = res.list;
        this.loading = false
        this.page.total = +res.total
      });
    },
    delete(row) {
      // return
      this.$post(this.$api.FARM_BLUEPRINT.DEL, {
        id: row,
      }).then((res) => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },
    onSuccess(file) {
     this.drawingOpenUrl = file;
    },
    Success(file) {
     this.imgOpenUrl = file;
      
    },
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "养殖场图纸",
            ca: "farmBlueprint_add",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "养殖场名称",
              component: "input",
              field: "name",
              defaultValue: "",
            },
          ],
        },
        page: { ...this.page },
      };
      return config;
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.farm-page {
  width: 100%;
  height: 100%;
}
</style>